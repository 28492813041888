export default [
    {
        type: 'input',
        label: '发布人',
        prop: "publishName",
    },
    {
        type: 'input',
        label: '公告标题',
        prop: "title",
    },
    {
        type: 'date',
        label: '发布开始',
        prop: 'publishStart',
        value: []
    },
    {
        type: 'date',
        label: '发布结束',
        prop: 'publishEnd',
        value: []
    },
]
