<template>
    <a-modal :dialog-style="{ top: '50px' }" v-bind="$attrs" :visible="visible" :footer="null" :closable="true"
        @cancel="$emit('update:visible', false)">
        <div class="announce-details-page">
            <h3>{{ formData.title }}</h3>
            <div style="color: #ccc; text-align: center;">{{ formData.publish_at | dateFormater }}</div>
            <div class="content">
                <div v-html="formData.content"></div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import dayjs from 'dayjs';
import { privateInfo } from '@/api/ForecastShipping';
export default {
    props: {
        passData: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            formData: {},
            loading: false
        }
    },
    watch: {
        visible: {
            handler(v) {
                if (v) {
                    this.getInfo()
                }
            }
        }
    },
    filters: {
        dateFormater(val) {
            if (!val) return '-'
            return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    methods: {
        getInfo() {
            this.loading = true;
            return privateInfo({ announcementId: this.passData.id }).then(res => {
                this.formData = res.data
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}

</script>
<style lang='less' scoped>
.announce-details-page {
    >h3 {
        text-align: center;
        font-size: 24px;
        margin: 0;
        padding: 12px 0 4px 0;
    }

    .content {
        overflow: auto;
        text-align: center;
        height: calc(100vh - 270px)
    }

    .submit-form-btn {
        text-align: center;
        margin-top: 24px;
    }
}

::v-deep {
    .ant-modal-body {
        padding-top: 0;
    }
}
</style>
