<template>
    <div class="system-bulletin-page">
        <TabHeader title="公告"></TabHeader>
        <div style="height:15px"></div>
        <SearchBar :formList="form1" @search="search" @reset="reset"></SearchBar>
        <a-table size="small" rowKey="id" bordered :columns="columns" :data-source="tableData"
            style="margin-top: 20px;padding: 0 12px;" :scroll="{ x: 'max-content' }" :pagination="pagination">
            <template slot="action" slot-scope="record">
                <a class="btn-a" type="link" @click="detailsHandle(record)">公告详情</a>
            </template>
            <template slot="publishAt" slot-scope="record">
                {{ record | dateFmatter }}
            </template>
        </a-table>
        <details-vue :title="dialogOpts.title" :visible.sync="dialogOpts.visible" :width="dialogOpts.width"
            :pass-data="dialogOpts.passData"></details-vue>
    </div>
</template>

<script>
import form1 from "./curd/form1"
import columns from "./curd/columns";
import SearchBar from "@/components/SearchBar";
import { getAnnounceList } from '@/api/ForecastShipping';
import dayjs from "dayjs";
import DetailsVue from "./details.vue";
export default {
    components: {
        SearchBar,
        DetailsVue
    },
    data() {
        return {
            form: {

            },
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            dialogOpts: {
                title: '',
                visible: false,
                passData: {},
                width: '520px',
            },
            columns: Object.freeze(columns),
            form1: Object.freeze(form1)
        }
    },
    created() {
        this.search();
    },
    filters: {
        dateFmatter(val) {
            if (!val) return '-'
            return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        getData() {
            getAnnounceList(this.form).then(res => {
                const { list, total } = res.data;
                this.tableData = list
                this.pagination.total = total;
            })
        },
        detailsHandle(row) {
            this.dialogOpts.title = '公告详情';
            this.dialogOpts.width = '1200px';
            this.dialogOpts.passData = row;
            this.dialogOpts.visible = true;
        },
        search(val) {
            this.form = Object.assign(this.form, val)
            this.getData()
        },
        reset() {
            Object.keys(this.form).forEach(ele => {
                this.form[ele] = ""
            })
            this.getData()

        },
    }
}

</script>
<style lang='less' scoped></style>
