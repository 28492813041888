/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-30 12:02:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
export default [{
    title: '标题',
    dataIndex: 'title',
    key: 'title',
},
{
    title: '内容',
    dataIndex: 'describe',
    key: 'describe',
},
{
    title: '发布日期',
    dataIndex: 'publish_at',
    key: 'publish_at',
    scopedSlots: {
        customRender: 'publishAt'
    }
},
{
    title: '操作',
    key: 'action',
    scopedSlots: {
        customRender: 'action'
    },
},
]